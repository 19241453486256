<template>
  <router-view />
</template>

<script>
import axios from 'axios'
import { mapGetters } from 'vuex'

export default {
  name: 'App',
  metaInfo() {
    return {
      // if no subcomponents specify a metaInfo.title, this title will be used
      title: 'ERS Center Front',
      // all titles will be injected into this template
      titleTemplate:
        'ERS - %s'
    }
  },
  created() {
    axios.defaults.headers.common['x-access-token'] = this.loggedInUser ? this.loggedInUser.accessToken : null
    const mq = window.matchMedia('(prefers-color-scheme: dark)')
    mq.addEventListener('change', e => {
      this.$vuetify.theme.dark = e.matches
    })
  },
  computed: {
    ...mapGetters(['loggedInUser'])
  }
}
</script>
