import axios from 'axios'
import serverService from '@/services/server'
import notify from '@/utils/notify'
import router from '../../router'

export default {
  state: {
    loggedInUser:
      localStorage.getItem('userInfo') != null
        ? JSON.parse(localStorage.getItem('userInfo'))
        : null,
    loading: false,
    error: null,
  },
  getters: {
    loggedInUser: state => state.loggedInUser,
    loading: state => state.loading,
    error: state => state.error,
    role: state => state.loggedInUser && state.loggedInUser.user && state.loggedInUser.user.role,
  },
  mutations: {
    setUser(state, data) {
      state.loggedInUser = data
      state.loading = false
      state.error = null
    },
    setMfAuth(state, data) {
      console.log('commit setMfAuth()------->', data)
      state.loggedInUser = data
      state.loading = false
      state.error = null
      console.log('commit setMfAuth()---loggedInUser--->', state.loggedInUser)
    },
    setLogout(state) {
      state.loggedInUser = null
      state.loading = false
      state.error = null
    },
    setLoading(state, data) {
      state.loading = data
      state.error = null
    },
    setError(state, data) {
      state.error = data
      state.loggedInUser = null
      state.loading = false
    },
    clearError(state) {
      state.error = null
    }
  },
  actions: {
    login({ commit, dispatch }, data) {
      commit('clearError')
      commit('setLoading', true)
      serverService.login(data).then(r => {
        console.log('serverService.login(data) result--->', r)
        switch (r.code) {
          case 200 :
          case 201 :
          case 209 : {
            localStorage.setItem('userInfo', JSON.stringify(r.result))
            axios.defaults.headers.common['x-access-token'] = r.result.accessToken
            commit('setUser', r.result)
            dispatch('connectWS')
            if (r.code === 209 || r.code === 201) {
              notify.showError('비밀번호 변경 페이지로 이동 합니다.')
              router.push('/settings/resetPassword').then(() => { })
            }
            break
          }
          case 199 :
            /* 멀티팩터 로그인 이동 */
            localStorage.setItem('userInfo', JSON.stringify(r.result))
            axios.defaults.headers.common['x-access-token'] = r.result.accessToken
            commit('setUser', r.result)
            notify.showMessage('인증번호 입력이 필요 합니다.')
            router.push({ name: 'MFLogin' },
              () => { console.log('route onComplete') },
              () => { console.log('route aborted') }
            )
            console.log('------ end of case : 199 ---- ')
            break
          case 290 :
            commit('setError', '중복된 로그인 입니다. 문의 바랍니다.')
            break
          case 299 :
            commit('setError', '중복된 로그인 이며, 비밀번호가 만료 되었습니다. 문의 바랍니다.')
            break
          default :
            commit('setError', '알수없는 에러 (' + r.code + ') 문의 바랍니다.')
            break
        }
      }).catch((err) => {
        console.log('login() catch --->', err)
        commit('setError', '아이이와 패스워드를 확인해 주십시오')
      })
    },
    mfAuth({ commit, dispatch }, data) {
      serverService.mfAuth(data).then((r) => {
        console.log('action mfAuth result --->', r)
        localStorage.setItem('userInfo', JSON.stringify(r.result))
        axios.defaults.headers.common['x-access-token'] = r.result.accessToken
        commit('setMfAuth', r.result)
        dispatch('connectWS')
        router.push('/dashboard').then(() => { })
      }).catch((err) => {
        console.log('action mfAuth error ---> ', err)
        notify.showError(err.message)
      })
    },
    signOut({ commit, getters }) {
      serverService.logout()
      localStorage.removeItem('userInfo')
      axios.defaults.headers.common['x-access-token'] = undefined
      commit('setLogout')
      if (getters.ws != null) {
        getters.ws.close()
        commit('setSocket', null)
      }
    }
  }
}
