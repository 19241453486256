// Vuetify Documentation https://vuetifyjs.com

import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import { themePreset } from '../../src/themeConfig'
import i18n from '@/lang'

Vue.use(Vuetify)

export default new Vuetify({
  lang: {
    t: (key, ...params) => i18n.t(key, params) // vuetify i18n integration.
  },
  theme: themePreset.theme,
  rtl: themePreset.rtl
})
