/*
 * ============================
 * File: index.js
 * Project: Octavia-Admin
 * File Created: Thursday, 9th April 2020 2:11:05 am
 * Author:UILIB
 * AuthorUrl:https://ui-lib.com/
 * -----
 * Last Modified: Saturday, 18th April 2020 9:32:52 pm
 * Modified By: naime hossain (naime.hossain93@gmail.com)
 * -----
 * Copyright 2020 - 2021 UILIB, UILIB
 * ============================
 */

// Imports
import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store'
import authenticate from '@/auth/authenticate'
import appIndex from '@/views/app/Index'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior: (to, from, savedPosition) => {
    if (to.hash) return { selector: to.hash }
    if (savedPosition) return savedPosition

    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      component: appIndex,
      beforeEnter: authenticate,
      redirect: '/dashboard',

      children: [
        {
          path: '/dashboard',
          component: () => import('@/views/ers/Dashboard')
        },
        {
          path: '/agencyStat',
          component: () => import('@/views/ers/AgencyStat')
        },
        {
          path: '/accidentHistory',
          component: () => import('@/views/ers/AccidentHistory')
        },
        {
          path: '/dispatch',
          component: () => import('@/views/ers/Dispatch')
        },
        {
          path: '/counselor',
          component: () => import('@/views/ers/Counselor')
        },
        {
          path: '/workgroup',
          component: () => import('@/views/ers/Workgroup')
        }
      ],
    },
    {
      path: '/settings',
      component: appIndex,
      redirect: '/settings/my',
      children: [
        {
          path: 'my',
          component: () => import('@/views/ers/settings/MySetting')
        },
        {
          path: 'dashboard',
          component: () => import('@/views/ers/settings/DashboardSetting')
        },
        {
          path: 'messageTemplate',
          component: () => import('@/views/ers/settings/MessageTemplate')
        },
        {
          path: 'centerInfo',
          component: () => import('@/views/ers/settings/CenterInfo')
        },
        {
          path: 'resetPassword',
          component: () => import('@/views/ers/settings/ResetPassword')
        }
      ]
    },
    {
      path: '/app/sessions',
      component: () => import('@/views/app/sessions/Sessions'),

      children: [
        {
          path: 'sign-in',
          name: 'login',
          component: () => import('@/views/app/sessions/SignIn'),
        },
        {
          path: 'mf-login',
          name: 'MFLogin',
          component: () => import('@/views/app/sessions/MultiFactorLogin.vue'),
        },
        {
          path: 'error',
          component: () => import('@/views/app/sessions/Error'),
        },
        {
          path: 'forgot',
          component: () => import('@/views/app/sessions/Forgot'),
        },
        {
          path: 'passwordChange/:loginId/:errorCode',
          component: () => import('@/views/app/sessions/PasswordChange'),
          props: true
        }
      ],
    },
    {
      path: '*',
      redirect: '/'
    }
  ],
})

router.beforeEach((to, from, next) => {
  // If this isn't an initial page load.
  if (to.path) {
    // Start the route progress bar.
    store.dispatch('changeThemeLoadingState', true)
  }
  next()
})

router.afterEach(() => {
  setTimeout(() => store.dispatch('changeThemeLoadingState', false), 500)
})

export default router
