import Vue from 'vue'
import VueI18n from 'vue-i18n'
import { ko } from 'vuetify/lib/locale'

Vue.use(VueI18n)

const DEFAULT_LANG = 'ko'

const locales = {
  en: require('./i18n/en.json'),
  ko: {
    $vuetify: ko,
    ...require('./i18n/ko.json')
  }
}

const i18n = new VueI18n({
  locale: DEFAULT_LANG,
  messages: locales,
})

export default i18n
