import axios from 'axios'
import io from 'socket.io-client'
import notify from '@/utils/notify'

export default {
  state: {
    appBarTitle: 'ERS Center 빈 페이지',
    ws: null, // web socket
    newRequest: null, // 새로 들어온 request
    sound: new Audio('@/assets/sound/alarm.mp3'),
    centerMessages: [],
    messageAlarm: false,
  },
  getters: {
    appBarTitle: state => state.appBarTitle,
    ws: state => state.ws,
    newRequest: state => state.newRequest,
    sound: state => state.sound,
    centerMessages: state => state.centerMessages,
    messageAlarm: state => state.messageAlarm
  },
  mutations: {
    setAppBarTitle(state, data) {
      state.appBarTitle = data
    },
    setSocket(state, socket) {
      if (state.ws) {
        console.log('setSocket() --- socket exists---', state.ws)
        state.ws.disconnect()
        state.ws.removeAllListeners()
        state.ws = null
      }
      state.ws = socket
    },
    setNewRequest(state, request) {
      state.newRequest = request
    },
    setErsUpdate(state, request) {
      state.newRequest = request
    },
    pushCenterMessage(state, message) {
      state.centerMessages.splice(0, 0, message)
    },
    setCenterMessages(state, messages) {
      state.centerMessages = messages
    },
    clearCenterMessages(state, messages) {
      state.centerMessages = []
    },
  },
  actions: {
    connectWS({ commit, getters, dispatch }) {
      axios.defaults.headers.common['x-access-token'] = getters.loggedInUser.accessToken
      const centerCode = getters.loggedInUser.counselor.center.centerCode
      console.log('connecting websocket - [' + centerCode + ']')
      let ws = null
      try {
        ws = io('/' + centerCode, {
          extraHeaders: { 'x-access-token': getters.loggedInUser.accessToken },
          path: '/ws/socket.io',
          secure: true,
          reconnection: true,
          reconnectionAttempts:100,
          reconnectionDelay: 30*1000
        })
      } catch (e) {
        console.log(e)
      }
      ws.on('connect', () => {
        console.log('ws connect - ' + ws.id)
      })
      ws.on('time', (time) => {
        console.log(time)
      })
      ws.on('disconnect', () => {
        console.log('ws disconnect');
        if (getters.loggedInUser != null) {
          //ws.connect();
          alert("서버와 연결이 종료 되었습니다");
        }
      })
      ws.on('ers-message', (arg) => {
        console.log('ers message - data --->', arg)
        switch (arg.CMD) {
          case 'ERS_RECV':
            dispatch('newRequest', arg)
            break
          default:
            dispatch('ersUpdated', arg)
            break
        }
      })
      ws.on('message', (arg) => {
        console.log('message - ', arg)
        commit('pushCenterMessage', arg)
        if (this.state.messageAlarm) notify.showMessage('새로운 메시지가 있습니다 : ' + arg.message)
      })
      commit('setSocket', ws)
    },
    newRequest({ commit }, req) {
      commit('setNewRequest', req)
      notify.showErsMessage('새로운 요청이 있습니다.')
    },
    ersUpdated({ commit }, req) {
      commit('setErsUpdate', req)
      if (this.state.messageAlarm) notify.showErsMessage('ERS 상태가 변경 되었습니다.')
    }
  }
}
